import { useCallback, useState } from 'react';

import { Section, SectionProps } from './Section';
import { Header } from '@components/Test/Header';
import { Stack } from '@mui/material';

export type TestProps = Pick<SectionProps, 'isDetail'>;

export const Test = ({ isDetail = false }: TestProps) => {
  const [thirdCategoryCode, setThirdCategoryCode] = useState<string>('');

  const handleChange = useCallback((code: string) => {
    setThirdCategoryCode(code);
  }, []);

  return (
    <Stack spacing="12px" overflow="auto" direction="column" width="100%">
      <Header
        onChange={handleChange}
        thirdCategoryCode={thirdCategoryCode}
        isDetail={isDetail}
      />
      <Section thirdCategoryCode={thirdCategoryCode} isDetail={isDetail} />
    </Stack>
  );
};
