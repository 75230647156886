import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import {
  Measurements,
  QuestionInfo,
  TestCategory,
  TestQuestions,
  useTestCategoryList,
  useTestMeasurementList,
  useTestQuestionList,
} from '@modules/meta';

import { Tests, TestType } from '@core/constants/testType';
import { Snb } from './Snb';
import { Header as SectionHeader } from './Header';
import { Footer as SectionFooter } from './Footer';
import { TestTable, TestTableProps } from './TestTable';
import { getFirstCategoryCode } from '@components/Test/utils';
import { TestDetail, useTestDetail } from '@src/modules/patientTest';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { test } from '@src/recoils/test';
import { Box } from '@mui/material';
import { ScoreUnitTypeCode } from '@src/core/constants/enum';
const Wrap = styled('div')`
  display: flex;
  gap: 12px;
`;

const getScoreRecords = (measurements: Measurements[] | undefined) => {
  return (
    measurements
      ?.filter(
        ({ scoreInfo }) =>
          scoreInfo?.unitType?.code === ScoreUnitTypeCode.PERCENT ||
          scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT,
      )
      ?.map(({ measurementMappingId, scoreInfo }) => {
        const score =
          scoreInfo?.unitType?.code === ScoreUnitTypeCode.PERCENT
            ? '100'
            : scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT
            ? Math.max(
                ...(scoreInfo?.scoreDescription?.scores?.map(({ key }) => Number(key)) ??
                  []),
              )?.toString()
            : undefined;

        return {
          diagnosticMemo: null,
          testScoreRecordId: null,
          score,
          measurementMappingId,
        };
      }) ?? []
  );
};
export interface SectionProps extends Pick<TestTableProps, 'isDetail'> {
  thirdCategoryCode: string;
}

export const Section = ({ thirdCategoryCode, isDetail }: SectionProps) => {
  const { patientId, sessionId, testRecordId } = useParams();
  const [fourthCategoryCode, setFourthCategoryCode] = useState<string>('');
  const setTest = useSetRecoilState(test);
  const testType = useMemo(() => Tests[thirdCategoryCode], [thirdCategoryCode]);

  const { data: categoryList } = useTestCategoryList(
    {
      code: thirdCategoryCode ?? '',
    },
    {
      enabled: !!thirdCategoryCode,
    },
  );

  const { data: questions = {} as TestQuestions } = useTestQuestionList(
    {
      code: thirdCategoryCode,
    },
    {
      enabled: !!thirdCategoryCode,
    },
  );

  const category: TestCategory = useMemo(
    () => categoryList?.at(0) ?? ({} as TestCategory),
    [categoryList],
  );

  const hasSnb = useMemo(
    () => testType !== TestType.One_Page && category?.children?.length > 1,

    [testType, category],
  );

  const handleChangeCategory = useCallback((category: string) => {
    setFourthCategoryCode(category);
  }, []);

  const currentQuestionsInfo: QuestionInfo[] = useMemo(() => {
    if (testType !== TestType.One_Page) {
      return (
        questions?.questionInfo?.filter(
          ({ categoryCode }) => categoryCode === fourthCategoryCode,
        ) ?? []
      );
    } else return questions?.questionInfo ?? [];
  }, [testType, fourthCategoryCode, questions]);

  const { data: measurementList = [] } = useTestMeasurementList(
    {
      code: [
        ...new Set(
          questions?.questionInfo?.map(({ measurementCode }) => measurementCode),
        ),
      ],
    },
    {
      enabled: !!questions?.questionInfo?.length,
    },
  );

  const { data: testDetail } = useTestDetail(
    {
      patientId: Number(patientId),
      sessionId: Number(sessionId),
      code: [
        ...new Set(category?.children?.map(({ categoryCode }) => categoryCode) ?? []),
      ],
      recordId: Number(testRecordId),
    },
    {
      enabled:
        !!patientId &&
        !!sessionId &&
        !!testRecordId &&
        !!category?.children?.length &&
        !!measurementList?.length,
    },
  );

  useEffect(() => {
    if (categoryList) {
      setFourthCategoryCode(getFirstCategoryCode(categoryList));
    }
  }, [categoryList]);

  useEffect(() => {
    if (!testDetail || !measurementList || !questions) {
      return;
    }
    if (!testDetail?.length) {
      let newTest: TestDetail[] = [];
      if (testType === TestType.One_Page) {
        newTest =
          category?.children?.map(({ categoryCode }) => {
            const question = currentQuestionsInfo?.find(
              ({ categoryCode: questionCategoryCode }) =>
                categoryCode === questionCategoryCode,
            );

            const measurements = measurementList?.find(
              ({ key }) => key === question?.measurementCode,
            )?.measurements;

            const newDetail: TestDetail = {
              testCategoryRecordId: null,
              testCategoryCode: categoryCode,
              questionRecords: [],
              updated: true,
            };

            if (question) {
              const scoreRecords = getScoreRecords(measurements);

              newDetail.questionRecords = [
                {
                  testQuestionRecordId: null,
                  questionId: question.questionId,
                  scoreRecords,
                },
              ];
            }
            return newDetail;
          }) ?? [];
      } else if (testType === TestType.Measurement) {
        newTest =
          category?.children?.map(({ categoryCode }) => {
            const question = questions?.questionInfo?.find(
              ({ categoryCode: questionCategoryCode }) =>
                categoryCode === questionCategoryCode,
            );

            const newDetail: TestDetail = {
              testCategoryRecordId: null,
              testCategoryCode: categoryCode,
              questionRecords: [],
              updated: true,
            };
            const measurements = measurementList?.find(
              ({ key }) => key === question?.measurementCode,
            )?.measurements;

            if (question) {
              const scoreRecords = getScoreRecords(measurements);

              newDetail.questionRecords = [
                {
                  testQuestionRecordId: null,
                  questionId: question?.questionId,
                  scoreRecords,
                },
              ];
            }
            return newDetail;
          }) ?? [];
      } else {
        newTest =
          category?.children?.map(({ categoryCode }) => {
            const question = questions?.questionInfo?.filter(
              ({ categoryCode: questionCategoryCode }) =>
                categoryCode === questionCategoryCode,
            );

            const measurements = measurementList?.find(
              ({ key }) => key === question?.at(0)?.measurementCode,
            )?.measurements;

            const questionRecords =
              question?.map(({ questionId }) => {
                const scoreRecords = getScoreRecords(measurements);

                return {
                  testQuestionRecordId: null,
                  questionId,
                  scoreRecords,
                };
              }) ?? [];

            return {
              testCategoryRecordId: null,
              testCategoryCode: categoryCode,
              questionRecords,
              updated: true,
            };
          }) ?? [];
      }
      setTest(newTest);
    } else {
      setTest(testDetail);
    }
  }, [measurementList, testDetail, questions]);

  return (
    <Wrap>
      {hasSnb ? (
        <Snb
          menu={category?.children}
          onChange={handleChangeCategory}
          value={fourthCategoryCode}
        />
      ) : null}
      <Box
        flex={1}
        borderRadius={1}
        sx={{
          backgroundColor: 'white',
        }}
      >
        <SectionHeader
          isDetail={isDetail}
          key={fourthCategoryCode}
          categoryCode={fourthCategoryCode}
          title={category?.categoryName ?? ''}
          instruction={category?.instruction}
          useRecord={category?.children?.some(({ voiceRecording }) => voiceRecording)}
        />
        {currentQuestionsInfo?.length ? (
          <Box padding={5}>
            <TestTable
              measurementList={measurementList}
              testType={testType}
              isDetail={isDetail}
              categoryCode={fourthCategoryCode}
              screenShare={questions.screen_share}
              questions={currentQuestionsInfo}
              categories={testType === TestType.One_Page ? category?.children ?? [] : []}
            />
            {hasSnb ? (
              <SectionFooter
                onChangeCategory={handleChangeCategory}
                categories={category?.children}
                activeCategoryCode={fourthCategoryCode}
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Wrap>
  );
};
