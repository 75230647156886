import api from '@core/lib/api';
import axios from 'axios';

import {
  TestSessionListParams,
  TestSessionListResponse,
  TestSessionParams,
  TestDetailParams,
  TestDetailResponse,
  UploadVoiceRecordingParams,
  UploadVoiceRecordingResponse,
  TestSessionCommonParams,
  SessionId,
  TestParams,
  TestStatus,
  TestSummary,
} from './types';

const prefixUrl = (patientId: number) => `api/patients/${patientId}`;
export async function createTestSession({
  patientId,
  ...params
}: TestSessionParams): Promise<SessionId> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions`,
    method: 'post',
    data: params,
  });
  return data;
}

export async function getTestSessionList({
  patientId,
  ...params
}: TestSessionListParams): Promise<TestSessionListResponse> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions`,
    method: 'get',
    params,
  });
  return data;
}

export async function uploadVoiceRecording({
  patientId,
  ...params
}: UploadVoiceRecordingParams): Promise<UploadVoiceRecordingResponse> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/uploading-voice-recording`,
    method: 'post',
    data: params,
  });
  return data;
}

export async function deleteTestSession({
  patientId,
  sessionId,
}: TestSessionCommonParams): Promise<null> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}`,
    method: 'delete',
  });
  return data;
}

export async function getTestDetail({
  recordId,
  patientId,
  sessionId,
  code,
}: TestDetailParams): Promise<TestDetailResponse> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}/records/${recordId}`,
    method: 'get',
    params: {
      code,
    },
  });
  return data;
}

export async function createEditTest({
  patientId,
  sessionId,
  recordId,
  ...params
}: TestParams): Promise<null> {
  const audioUploadItems = params.categoryRecords.filter(({ audio }) => {
    return audio;
  });

  for (const item of audioUploadItems) {
    const extension = item?.audio?.type.split(';')[0].split('/')[1] || 'webm';
    const data = await uploadVoiceRecording({
      testSessionId: sessionId,
      testRecordId: recordId,
      testCategoryCode: item?.testCategoryCode ?? '',
      extension,
      patientId,
    });
    if (data) {
      await axios.put(data.presignedUrl ?? '', item.audio);
      delete item.audio;

      item.voiceRecordingPath = data.voiceRecordingPath;
    }
  }

  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}/records/${recordId}`,
    method: 'post',
    data: params,
  });
  return data;
}

export async function finishTestSession({
  sessionId,
  patientId,
}: TestSessionCommonParams): Promise<null> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}`,
    method: 'patch',
  });
  return data;
}

export async function getTestStatus({
  patientId,
  sessionId,
}: TestSessionCommonParams): Promise<TestStatus> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}/in-progress-status`,
    method: 'get',
  });
  return data;
}

export async function getSummary({
  patientId,
  sessionId,
}: TestSessionCommonParams): Promise<TestSummary> {
  const { data } = await api({
    url: `${prefixUrl(patientId)}/test-sessions/${sessionId}/summary`,
    method: 'get',
  });
  return data;
}
