import { createTheme } from '@mui/material';
import {
  blue,
  cyan,
  grape,
  green,
  grey,
  indigo,
  lime,
  orange,
  pink,
  red,
  teal,
  violet,
  white,
  yellow,
} from '@core/constants/colors';

export const SMALL = '32px';
export const MEDIUM = '40px';
export const LARGE = '48px';
export const theme = createTheme(
  {
    components: {
      MuiTable: {
        defaultProps: {
          stickyHeader: true,
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: grey[300],
          },
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '24px',
            fontWeight: 700,
            color: grey[800],
            padding: '0',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '16px 24px 24px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px',
            gap: '16px',
            '>:not(:first-of-type)': {
              marginLeft: 0,
            },
            borderTop: `1px solid ${grey[200]}`,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {},
          indicator: {
            background: indigo[700],
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            flex: 1,
            fontSize: '16px',
            fontWeight: '700',
            color: grey[500],
            '&.Mui-selected': {
              color: indigo[700],
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            '&:hover, &:active': {
              backgroundColor: grey['50'],
            },
          },
          head: {
            cursor: 'default',
            '&:hover, &:active': {
              backgroundColor: 'inherit',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '12px',
            lineHeight: '28px',
            color: grey[900],
            fontWeight: 400,
            fontSize: '16px',
            borderBottom: `1px solid ${grey[200]}`,
          },
          head: {
            borderTop: `1px solid ${grey[200]}`,
            color: grey[600],
            fontWeight: 700,
          },
        },
      },
      MuiPagination: {
        defaultProps: {
          showFirstButton: true,
          showLastButton: true,
          color: 'primary',
        },
        styleOverrides: {},
      },
      MuiPaginationItem: {
        defaultProps: {},
        styleOverrides: {
          root: {
            height: '32px',
            width: '32px',
            color: grey[900],
            fontSize: '14px',
            fontWeight: 400,
            border: 'none',
            '&.Mui-selected': {
              border: 'none',
              color: indigo[700],
              backgroundColor: indigo[50],
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: grey[300],
            '&.Mui-checked': {
              color: indigo[700],
            },
            '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.26)' },
            '& .MuiSvgIcon-root': {
              fontSize: 20,
            },
            '& .MuiSvgIcon-fontSizeSmall': {
              fontSize: 16,
            },
            padding: 0,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: { color: indigo[700] },
          rail: {
            opacity: 0.5,
            color: indigo[200],
          },
          valueLabel: {
            background: 'transparent',
            color: indigo[700],
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '24px',
            top: 0,
            padding: 0,
          },
          thumb: {
            width: '16px',
            height: '16px',
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            gap: '20px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            gap: '8px',
            margin: 0,
            '.MuiFormControlLabel-label': {
              color: grey[800],
              fontWeight: 400,
              fontSize: '16px',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              backgroundColor: `${grey[100]} !important`,
            },
            '&:hover, &:active': {
              backgroundColor: grey['50'],
            },
          },
          input: {
            color: grey[900],
            padding: '8.5px 12px',
            '&.Mui-disabled': {
              backgroundColor: `${grey[100]} !important`,
              WebkitTextFillColor: grey[600],
            },
            '&::placeholder, &::-webkit-input-placeholder,&::-moz-placeholder,&::-ms-input-placeholder':
              {
                color: grey[500],
              },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginRight: 0,
            marginLeft: '8px',
            '.clear-button': { opacity: '0' },
            '.MuiIconButton-root': {
              padding: '4px',
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: '8px',
            marginTop: '8px !important',
            color: grey[50],
            fontSize: '14px',
            maxWidth: 'fit-content',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: '0 12px 0 4px',
            '.MuiOutlinedInput-notchedOutline, :hover .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: red[700],
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: grey[300],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: indigo[700],
              borderWidth: '1px',
            },
            '&.Mui-focused .MuiInputAdornment-root .clear-button': {
              opacity: '1',
            },
          },
          notchedOutline: {
            border: `1px solid ${grey[300]} `,
            borderRadius: '4px',
          },
          input: {
            padding: '8.5px 4px 8.5px 8px',
            '&:hover, &:active': {
              backgroundColor: grey['50'],
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px',
          },
        },
      },
      MuiButton: {
        defaultProps: {},
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: '4px',
            fontStyle: 'normal',
            fontWeight: 700,
          },
          endIcon: {
            svg: {
              width: '18px',
              height: '18px',
            },
          },
          startIcon: {
            svg: {
              width: '18px',
              height: '18px',
            },
          },
          sizeSmall: { height: SMALL, padding: '10px 12px', fontSize: '14px' },
          sizeMedium: { height: MEDIUM, padding: '10px 16px', fontSize: '15px' },
          sizeLarge: {
            height: LARGE,
            padding: '10px 20px',
            fontSize: '16px',
          },
          text: {
            color: grey['500'],
            backgroundColor: white,
            '&:hover, &:active': {
              backgroundColor: grey['50'],
            },
            '&:disabled': {
              color: grey['300'],
            },
          },
          textPrimary: {
            color: indigo['700'],
            '&:hover, &:active': {
              backgroundColor: indigo['50'],
            },
          },
          textSecondary: {
            color: grey['700'],
            '&:hover, &:active': {
              backgroundColor: grey['50'],
            },
          },
          textSuccess: {
            color: green['700'],
            '&:hover, &:active': {
              backgroundColor: green['50'],
            },
          },
          textError: {
            color: red['700'],
            '&:hover, &:active': {
              backgroundColor: red['50'],
            },
          },
          outlined: {
            color: grey['700'],
            border: `1px solid ${grey['300']}`,
            '&:disabled': {
              color: grey['300'],
              border: `1px solid ${grey['300']}`,
            },
            '&:hover, &:active': {
              backgroundColor: grey['50'],
              border: `1px solid ${grey['300']}`,
            },
          },
          outlinedPrimary: {
            color: indigo['700'],
            border: `1px solid ${indigo['700']}`,
            '&:hover, &:active': {
              backgroundColor: indigo['50'],
              border: `1px solid ${indigo['50']}`,
            },
          },
          outlinedSecondary: {
            color: grey['700'],
            '&:hover, &:active': {
              backgroundColor: grey['50'],
              border: `1px solid ${grey['700']}`,
            },
          },
          outlinedSuccess: {
            color: green['700'],
            border: `1px solid ${green['700']}`,
            '&:hover, &:active': {
              backgroundColor: green['50'],
              border: `1px solid ${green['700']}`,
            },
          },
          outlinedError: {
            color: red['700'],
            border: `1px solid ${red['700']}`,
            '&:hover, &:active': {
              backgroundColor: red['50'],
              border: `1px solid ${red['700']}`,
            },
          },
          contained: {
            color: white,
            backgroundColor: grey['500'],
            '&:hover, &:active': {
              backgroundColor: grey['600'],
            },
            '&:disabled': {
              color: white,
              backgroundColor: grey['200'],
            },
          },
          containedPrimary: {
            backgroundColor: indigo['700'],
            '&:hover, &:active': {
              backgroundColor: indigo['900'],
            },
          },
          containedSecondary: {
            backgroundColor: grey['700'],
            '&:hover, &:active': {
              backgroundColor: indigo['900'],
            },
          },
          containedSuccess: {
            backgroundColor: green['700'],
            '&:hover, &:active': {
              backgroundColor: green['900'],
            },
          },
          containedError: {
            backgroundColor: red['700'],
            '&:hover': {
              backgroundColor: red['900'],
            },
          },
        },
      },
    },
  },
  {
    color: {
      white,
      grey,
      violet,
      cyan,
      indigo,
      orange,
      red,
      pink,
      grape,
      blue,
      teal,
      green,
      lime,
      yellow,
    },
  },
);
