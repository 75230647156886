export const enum FormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export const enum PlayingFlag {
  PLAY = 'play',
  PAUSE = 'pause',
  OFF = 'off',
}

export const enum ScoreUnitTypeCode {
  POINT = 'POINT',
  CHOICE = 'CHOICE',
  CHOICE2 = 'CHOICE2',
  TEXT = 'TEXT',
  COUNT = 'COUNT',
  SPEED = 'SPEED',
  TIME = 'TIME',
  PERCENT = 'PERCENT',
}

export const enum HighestEduLevel {
  ILLITERATE = 'ILLITERATE',
  NO_EDU = 'NO_EDU',
  ELEMENTARY = 'ELEMENTARY',
  JUNIOR_HIGH = 'JUNIOR_HIGH',
  SENIOR_HIGH = 'SENIOR_HIGH',
  COLLEGE = 'COLLEGE',
  POST_GRADUATE = 'POST_GRADUATE',
}

export const enum Handedness {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  AMBIDEXTROUS = 'AMBIDEXTROUS',
}
export const enum Denture {
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  UPPER_PARTIAL = 'UPPER_PARTIAL',
  LOWER_PARTIAL = 'LOWER_PARTIAL',
}
export const enum AuditoryAcuity {
  NORMAL = 'NORMAL',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTH = 'BOTH',
}
export const enum VisualAcuity {
  NORMAL = 'NORMAL',
  EYE_GLASSES = 'EYE_GLASSES',
  READING_GLASSES = 'READING_GLASSES',
}
export const enum Sex {
  FEMALE = 'F',
  MALE = 'M',
}

export enum DialogType {
  Form,
  Confirm,
}
export const enum MentalStatus {
  ALERT = 'ALERT',
  DROWSSY = 'DROWSSY',
  OTHERS = 'OTHERS',
}

export const enum MembershipType {
  PERSONAL = 'PERSONAL',
  INSTITUTION = 'INSTITUTION',
}

export const HighestEduLevelYear: { [key in HighestEduLevel]: number | undefined } = {
  [HighestEduLevel.ILLITERATE]: 1,
  [HighestEduLevel.NO_EDU]: 1,
  [HighestEduLevel.ELEMENTARY]: 6,
  [HighestEduLevel.JUNIOR_HIGH]: 9,
  [HighestEduLevel.SENIOR_HIGH]: 12,
  [HighestEduLevel.COLLEGE]: 16,
  [HighestEduLevel.POST_GRADUATE]: undefined,
};
