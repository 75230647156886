import * as React from 'react';
import Slider, { SliderProps } from '@mui/material/Slider';

export type PercentSliderProps = SliderProps;
export default function PercentSlider(props: PercentSliderProps) {
  return (
    <Slider
      size="small"
      aria-label="Default"
      valueLabelDisplay="on"
      valueLabelFormat={(num: number) => {
        return `${num}%`;
      }}
      {...props}
    />
  );
}
