import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Dialog } from '../Dialog';
import { Stack, Typography } from '@mui/material';
import { InputText } from '../InputText';
import { scoreRecordInfosSelector } from '@src/recoils/test';
import { useRecoilState } from 'recoil';

export interface CommentPopupProps {
  onCancel: () => void;
  categoryCode: string;
  questionId: number;
  measurementMappingId: number;
  isDetail?: boolean;
}

export const CommentPopup = ({
  onCancel,
  categoryCode,
  questionId,
  measurementMappingId,
  isDetail,
}: CommentPopupProps) => {
  const [comment, setComment] = useState<string>();
  const [scoreRecord, setScoreRecord] = useRecoilState(
    scoreRecordInfosSelector({ categoryCode, questionId, measurementMappingId }),
  );

  const handleOk = useCallback(() => {
    if (!isDetail) {
      setScoreRecord({ diagnosticMemo: comment });
    }
    onCancel();
  }, [isDetail, onCancel, setScoreRecord, comment]);

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setComment(value);
    },
    [],
  );
  useEffect(() => {
    setComment(scoreRecord?.diagnosticMemo ?? '');
  }, [scoreRecord]);

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      okButtonProps={{
        onClick: handleOk,
      }}
      cancelButtonProps={{
        display: !isDetail,
      }}
      maxWidth="xs"
      fullWidth
    >
      <Stack gap={3} alignItems="center">
        <Typography fontSize={'24px'} fontWeight={700}>
          특이 사항을 적어주세요.
        </Typography>
        <InputText
          minRows={5}
          fullWidth
          multiline
          placeholder="특이사항"
          value={comment}
          disabled={isDetail}
          onChange={handleChange}
        />
      </Stack>
    </Dialog>
  );
};
