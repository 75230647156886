import api from '@core/lib/api';
import {
  Patient,
  PatientRequest,
  PatientList,
  PatientResponse,
  MedicalHistory,
  MedicalHistoryParams,
} from './types';

export async function getPatients(params: PatientRequest): Promise<PatientList> {
  const { data } = await api({
    url: 'api/patients',
    method: 'get',
    params,
  });
  return data;
}

export async function getPatient(id: number): Promise<Patient> {
  const { data } = await api({
    url: `api/patients/${id}`,
    method: 'get',
  });
  return data;
}

export async function addPatient(patient: Patient): Promise<PatientResponse> {
  const { data } = await api({
    url: '/api/patients',
    method: 'post',
    data: patient,
  });
  return data;
}

export async function updatePatient({
  patient,
  patientId,
}: {
  patient: Patient;
  patientId: number;
}): Promise<PatientResponse> {
  const { data } = await api({
    url: `/api/patients/${patientId}`,
    method: 'put',
    data: patient,
  });
  return data;
}

export async function deletePatient(patientId: number): Promise<PatientResponse> {
  const { data } = await api({
    url: `/api/patients/${patientId}`,
    method: 'delete',
  });
  return data;
}

export async function getMedicalHistory(id: number): Promise<MedicalHistory> {
  const { data } = await api({
    url: `api/patients/${id}/medical-history`,
    method: 'get',
  });
  return data;
}

export async function addMedicalHistory({
  patientId,
  ...params
}: MedicalHistoryParams): Promise<MedicalHistory> {
  const { data } = await api({
    url: `api/patients/${patientId}/medical-history`,
    method: 'post',
    data: params,
  });
  return data;
}
