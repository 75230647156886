import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { TestDetail } from 'src/modules/patientTest';

const { persistAtom } = recoilPersist();

export const test = atom<TestDetail[]>({
  key: 'test',
  default: [],
  effects: [persistAtom],
});
