import { TestType } from '@core/constants/testType';
import { MeasurementInfo, TestCategories } from '@modules/meta';

import { OnePageTable, QuestionTable, MeasurementTable } from './TestTableByTestType';
import { styled } from '@mui/material';
import { TableProps } from './Table';

const Wrap = styled('div')`
  width: 100%;
`;
export interface TestTableProps
  extends Omit<TableProps, 'questionId' | 'measurements' | 'header'> {
  testType: TestType;
  categories?: TestCategories;
  measurementList: MeasurementInfo[];
}

const ContentWrap = styled('div')`
  font-size: 16px;
  color: ${({ theme }) => theme.color.grey[900]};
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 28px;
`;

const Content = ({
  testType,
  questions,
}: Pick<TestTableProps, 'questions' | 'testType'>) => {
  return testType === TestType.Measurement && questions?.at(0)?.content ? (
    <ContentWrap>{questions?.at(0)?.content}</ContentWrap>
  ) : null;
};
export const TestTable = ({
  categories,
  questions = [],
  screenShare,
  testType,
  categoryCode,
  isDetail,
  measurementList,
}: TestTableProps) => {
  return (
    <Wrap>
      <Content testType={testType} questions={questions} />
      {testType === TestType.Question ? (
        <QuestionTable
          isDetail={isDetail}
          measurementInfo={measurementList}
          screenShare={screenShare}
          questionInfo={questions}
          categoryCode={categoryCode}
        />
      ) : testType === TestType.One_Page ? (
        <OnePageTable
          isDetail={isDetail}
          questionInfo={questions}
          screenShare={screenShare}
          categories={categories}
          measurementInfo={measurementList}
        />
      ) : (
        <MeasurementTable
          screenShare={screenShare}
          questionInfo={questions[0]}
          isDetail={isDetail}
          measurementInfo={measurementList}
          categoryCode={categoryCode}
        />
      )}
    </Wrap>
  );
};
