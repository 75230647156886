import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useCallback } from 'react';

export type Option = {
  value: string;
  label: string;
};
export interface SelectProps extends Omit<MuiSelectProps, 'onChange'> {
  options?: Option[];
  onChange: (value: Option['value']) => void;
}
export const Select = ({ options, onChange, ...props }: SelectProps) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const {
        target: { value },
      } = e;
      if (onChange) {
        onChange(value as Option['value']);
      }
    },
    [onChange],
  );

  return (
    <MuiSelect
      {...props}
      onChange={handleChange}
      MenuProps={{
        sx: {
          marginTop: '10px',
        },
      }}
    >
      {options?.map(({ value, label }, index) => (
        <MenuItem key={index} value={value}>
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};
