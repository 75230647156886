import { TestCategories } from '@modules/meta';

export const getFirstCategoryCode = (testCategories: TestCategories): string => {
  const firstCategory = testCategories?.at(0);
  if (firstCategory?.children?.length) {
    return getFirstCategoryCode(testCategories[0].children);
  } else {
    return firstCategory?.categoryCode ?? '';
  }
};
