export const DUPLICATE_ID = '이미 사용중이거나 탈퇴한 아이디입니다.';
export const RE_PASSWORD_INVALID = '비밀번호가 일치하지 않습니다.';
export const NO_CHECK_ID = '아이디 중복체크를 해주세요.';
export const SUCCESS_DUPLICATE_ID = '사용할 수 있는 아이디입니다.';
export const TEST_FINISH = '검사를 모두 완료했습니다.';
export const INVALID_BIRTH_DAY_MONTH = '월을 선택하세요.';

export const ERROR_MESSAGE = '에러가 발생했어요!';
export const SUCCESS_SAVE = '저장에 성공했어요!';
export const SUCCESS_REGISTER = '환영해요! 회원가입에 성공했어요!';
export const SUCCESS_UPDATE_PATIENT = '피검자 정보를 수정했어요!';
export const SUCCESS_ADD_PATIENT = '새로운 피검자를 추가했어요!';
export const SUCCESS_ADD_ACCOUNT = '새로운 검사자를 추가했어요!';
export const SUCCESS_RESET_PASSWORD = '비밀번호를 재설정했어요!';
export const SUCCESS_END_SESSION = '세션을 제출했어요!';
