import { useCallback, useState } from 'react';
import { Dialog, DialogProps } from '@src/components/Dialog';
import { merge } from 'lodash';

export type UseConfirmProps = Omit<DialogProps, 'onClose' | 'open'>;

export const useConfirm = ({ ...props }: UseConfirmProps) => {
  const [isOpened, setBeOpened] = useState<boolean>(false);
  const [confirmOptions, setConfirmOptions] = useState(props);

  const close = useCallback(() => {
    setBeOpened(false);
  }, []);

  const open = useCallback(
    (props: UseConfirmProps) => {
      setBeOpened(true);
      setConfirmOptions((prev) => {
        return merge(prev, props);
      });
    },
    [setConfirmOptions, setBeOpened],
  );
  const rendered = <Dialog {...confirmOptions} open={isOpened} onClose={close} />;

  return { rendered, open, close };
};
