import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { loginUrl } from '@modules/auth';
import { logout, setAccessToken, getAccessToken, getRefreshToken } from './auth';
import qs from 'qs';

const baseURL = import.meta.env.VITE_BASE_URL ?? '';
const MAX_TIMEOUT = 60000;

function paramsSerializer(params: unknown): string {
  return qs.stringify(params, { arrayFormat: 'repeat' });
}

const initialConfig = Object.freeze({
  baseURL,
  timeout: MAX_TIMEOUT,
  paramsSerializer: paramsSerializer,
});

const createApiInstance = (bearerJwt = '') => {
  const instance = axios.create({
    ...initialConfig,
  });
  instance.defaults.headers.common.Authorization = bearerJwt;
  return instance;
};
const api = createApiInstance(getAccessToken(true));

const onError = async (error: AxiosError) => {
  Sentry.captureException(error);
  if (
    error?.response?.status === 401 &&
    !error?.request.responseURL?.endsWith(loginUrl)
  ) {
    try {
      const refreshApi = createApiInstance();
      const { data } = await refreshApi({
        url: 'api/auth/refresh',
        method: 'get',
        headers: {
          REFRESH_TOKEN: getRefreshToken(),
        },
      });
      setAccessToken(data);
      const retryConfig = {
        ...error.config,
        headers: { Authorization: `Bearer ${data}` },
      };
      return api(retryConfig);
    } catch (e) {
      logout();
    }
  } else throw { ...(error?.response?.data ?? {}) };
};
api.interceptors.response.use((result) => result, onError);

export const setApiJwt = (token: string, prefix = 'Bearer') => {
  api.defaults.headers.common.Authorization = `${prefix} ${token}`;
};
export default api;
