import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiError } from '@core/types/common';
import { createEditTest } from '../api';
import { TestParams } from '@modules/patientTest';
import { TEST_KEY } from '../key';

export const useCreateEditTest = (
  options: UseMutationOptions<null, ApiError, TestParams> = {},
): UseMutationResult<null, ApiError, TestParams> => {
  const queryClient = useQueryClient();
  return useMutation<null, ApiError, TestParams>({
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [TEST_KEY, 'detail'] });

      if (options?.onSuccess instanceof Function) {
        options.onSuccess(...args);
      }
    },
    mutationFn: createEditTest,
  });
};
