import cookieStorage from './cookie-storage';
export const AUTH_KEY = 'auth';

const enum Token {
  refresh = 'refresh',
  access = 'access',
}

const DEFAULT_TOKEN = {
  access: '',
  refresh: '',
};

function getToken(key: Token, bearer: boolean) {
  const prefix = bearer ? 'Bearer ' : '';
  let cookieToken;
  try {
    const cookie = JSON.parse(cookieStorage.getItem(AUTH_KEY) as string) || DEFAULT_TOKEN;
    cookieToken = cookie[AUTH_KEY][key];
  } catch {
    cookieToken = undefined;
  }
  const token: string | undefined = cookieToken;
  return token ? `${prefix}${token}` : token;
}

function setToken(key: Token, token: string) {
  try {
    const auth = JSON.parse(cookieStorage.getItem(AUTH_KEY) as string);
    const newAuth = { ...(auth[AUTH_KEY] ?? {}) };
    newAuth[key] = token;

    cookieStorage.setItem(AUTH_KEY, JSON.stringify({ [AUTH_KEY]: newAuth }));
  } catch (error) {
    console.log(error);
  }
}

export const getAccessToken = (bearer = false) => {
  return getToken(Token.access, bearer);
};

export const setAccessToken = (token: string): void => {
  setToken(Token.access, token);
};

export const getRefreshToken = (bearer = false) => {
  return getToken(Token.refresh, bearer);
};

const clearToken = (): void => {
  cookieStorage.setItem(AUTH_KEY, JSON.stringify(DEFAULT_TOKEN));
};

// api level에서 로그아웃 처리
export function logout() {
  clearToken();
  location.href = '/';
}
