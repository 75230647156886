import { MeasurementInfo, QuestionInfo } from '@modules/meta';
import { Test } from './Test';
import { ScoreUnitTypeCode } from '@core/constants/enum';
import { TableProps } from '../Table';
import { countHangulSyllables } from '@src/core/lib/utils';

export interface MeasurementTableProps
  extends Pick<TableProps, 'screenShare' | 'isDetail' | 'categoryCode'> {
  questionInfo: QuestionInfo;
  measurementInfo: MeasurementInfo[];
}
export const MeasurementTable = ({
  measurementInfo,
  questionInfo,
  screenShare,
  isDetail,
  categoryCode,
}: MeasurementTableProps) => {
  const measurements =
    measurementInfo?.find(({ key }) => key === questionInfo?.measurementCode)
      ?.measurements ?? [];

  const scoreDescription = measurements?.find(
    ({ scoreInfo }) => scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT,
  )?.scoreInfo?.scoreDescription?.scores;
  return (
    <Test
      tableProps={{
        categoryCode,
        screenShare,
        measurements: measurements,
        questionId: questionInfo.questionId,
        isDetail,
        contentLength: countHangulSyllables(questionInfo?.content),
      }}
      scoreDescription={scoreDescription}
    />
  );
};
