import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';

const Wrap = styled('div')`
  background-color: ${({ theme }) => theme.color.grey[100]};
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  min-height: 100vh;
`;

type BlankLayoutProps = PropsWithChildren;
const BlankLayout = ({ children }: BlankLayoutProps) => {
  return <Wrap>{children}</Wrap>;
};
export default BlankLayout;
