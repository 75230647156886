import { styled } from '@mui/material';
import { css } from '@emotion/react';
import { IconInfo } from '@core/icons';
import { Scores } from '@modules/meta';
import { TableProps, Table } from '../Table';
import { Tooltip } from '@mui/material';

const TestWrapHeader = styled('div')`
  display: flex;
  margin-bottom: 13px;
`;

const TitleWrap = styled('div')`
  display: flex;
`;

const QuestionTitle = styled('div')`
  color: ${({ theme }) => theme.color.grey[800]};
  font-weight: 700;
  font-size: 18px;
`;
const QuestionInfoWrap = styled('div')`
  color: ${({ theme }) => theme.color.grey[500]};
  font-weight: 400;
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: default;
`;

export const Test = ({
  categoryName,
  scoreDescription,
  tableProps,
}: {
  categoryName?: string;
  scoreDescription?: Scores | undefined;
  tableProps: TableProps;
}) => {
  return (
    <div>
      <TestWrapHeader
        css={css`
          justify-content: ${categoryName ? 'space-between' : 'end'};
        `}
      >
        <TitleWrap>
          {categoryName ? <QuestionTitle>{categoryName}</QuestionTitle> : null}
        </TitleWrap>
        {scoreDescription && !!scoreDescription?.length ? (
          <Tooltip
            title={scoreDescription
              ?.map(({ key, value }) => `${key}: ${value}`)
              ?.join(' / ')}
          >
            <QuestionInfoWrap>
              <IconInfo />
              점수기준
            </QuestionInfoWrap>
          </Tooltip>
        ) : null}
      </TestWrapHeader>
      <Table {...tableProps} />
    </div>
  );
};
