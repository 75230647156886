import { useRecoilValue } from 'recoil';
import { test } from '@recoils/test';
import { useCreateEditTest } from '@modules/patientTest';
import { useConfirm } from '@core/hooks/useConfirm';
import { useSnackbar } from './useSnackbar';
import { SUCCESS_SAVE } from '../constants/message';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { TEST_KEY } from '@src/modules/patientTest/key';

export const useSaveTest = (
  patientId: number,
  sessionId: number,
  testRecordId: number,
) => {
  const { add } = useSnackbar();
  const queryClient = useQueryClient();
  const testState = useRecoilValue(test);
  const saveTest = useCreateEditTest({
    onSuccess: () => {
      add({ message: SUCCESS_SAVE });
      queryClient.invalidateQueries({ queryKey: [TEST_KEY, 'status'] });
    },
  });

  const moveConfirm = useConfirm({
    maxWidth: 'xs',
    fullWidth: true,
    children: (
      <Typography fontWeight="bold" fontSize={24} textAlign="center">
        저장이 안 된 정보가 있어요!
      </Typography>
    ),
    cancelButtonProps: {
      children: '저장하지 않고 이동',
    },
    okButtonProps: {
      children: '저장 후 이동',
    },
  });
  const check = () => {
    return testState?.some(({ updated }) => updated);
  };

  const makeCategoryRecords = () => {
    return testState?.map(({ ...test }) => {
      delete test.voiceRecordingUrl;
      delete test.duration;
      delete test.regions;
      delete test.updated;
      return { ...test };
    });
  };

  const save = async () => {
    if (check()) {
      return await saveTest.mutateAsync({
        recordId: Number(testRecordId),
        categoryRecords: makeCategoryRecords(),
        sessionId: Number(sessionId),
        patientId: Number(patientId),
      });
    } else {
      return add({ message: SUCCESS_SAVE });
    }
  };

  return { check, save, moveConfirm };
};
