import { styled } from '@mui/material';
import { Recorder } from '@src/components/Recorder/Recorder';
import { ScoreUnitsProps } from '@components/Test/Section/TestTable/ScoreUnits';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useSaveTest } from '@core/hooks/useSaveTest';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

const Wrap = styled('div')`
  padding: 40px 40px 0px;
  position: sticky;
  top: 0px;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 9;
`;
const Title = styled('div')`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey[800]};
`;

const Instruction = styled('span')`
  font-size: 16px;
  color: ${({ theme }) => theme.color.grey[600]};
  font-weight: 400;
`;

export interface HeaderProps extends Pick<ScoreUnitsProps, 'isDetail' | 'categoryCode'> {
  title: string;
  instruction?: string;
  useRecord?: boolean;
}

const HeaderTop = ({ isDetail, title }: Pick<HeaderProps, 'title' | 'isDetail'>) => {
  const { patientId, sessionId, testRecordId } = useParams();

  const { save } = useSaveTest(
    Number(patientId),
    Number(sessionId),
    Number(testRecordId),
  );

  const handleSave = useCallback(() => {
    save();
  }, [save]);

  return (
    <Stack justifyContent="space-between" alignItems="center" mb={2} direction="row">
      <Title>{title}</Title>
      {!isDetail && (
        <Button variant="contained" onClick={handleSave}>
          저장
        </Button>
      )}
    </Stack>
  );
};

export const Header = ({
  title,
  instruction,
  useRecord = false,
  categoryCode,
  isDetail,
}: HeaderProps) => {
  return (
    <Wrap>
      <HeaderTop title={title} isDetail={isDetail} />
      <Box>{instruction ? <Instruction>{instruction}</Instruction> : null}</Box>
      {useRecord ? <Recorder categoryCode={categoryCode} isDetail={isDetail} /> : null}
    </Wrap>
  );
};
