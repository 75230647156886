import { MeasurementInfo, QuestionInfo } from '@modules/meta';
import { Test } from './Test';
import { TableProps } from '@components/Test/Section/TestTable/Table';

export interface QuestionTableProps
  extends Pick<TableProps, 'screenShare' | 'isDetail' | 'categoryCode'> {
  questionInfo: QuestionInfo[];
  measurementInfo: MeasurementInfo[];
}
export const QuestionTable = ({
  measurementInfo,
  questionInfo,
  screenShare,
  isDetail,
  categoryCode,
}: QuestionTableProps) => {
  const measurements =
    measurementInfo?.find(({ key }) => key === questionInfo?.at(0)?.measurementCode)
      ?.measurements ?? [];
  return (
    <Test
      tableProps={{
        screenShare,
        measurements: measurements,
        questions: questionInfo,
        isDetail,
        categoryCode,
      }}
      scoreDescription={measurements[0]?.scoreInfo?.scoreDescription?.scores}
    />
  );
};
