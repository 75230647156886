import { Fragment, Suspense, lazy } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { useRoutes, Routes, Route, Navigate } from 'react-router-dom';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';

import routes from './App.route';
import './App.css';

import { userSelector } from './recoils/auth';
import BlankLayout from '@components/Layout/BlankLayout';
import { useSnackbar } from './core/hooks/useSnackbar';
import { ERROR_MESSAGE } from './core/constants/message';
import { ApiError } from './core/types/common';
import { styled } from '@mui/material';

const Login = lazy(() => import('@pages/Login'));
const FindId = lazy(() => import('@pages/FindId'));
const Register = lazy(() => import('@pages/Register'));
const RestorePassword = lazy(() => import('@pages/RestorePassword'));

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#fff',
    color: theme?.color?.green[700],
    border: `1px solid ${theme?.color?.green[700]}`,
    boxShadow: ' -4px 4px 8px 0px #0000001A',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#fff',
    color: theme?.color?.red[700],
    border: `1px solid ${theme?.color?.red[700]}`,
    boxShadow: ' -4px 4px 8px 0px #0000001A',
  },
}));

const AppChildren = () => {
  const user = useRecoilValue(userSelector);
  const element = useRoutes(routes);
  return (
    <Fragment>
      {user ? (
        element
      ) : (
        <BlankLayout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/find-id" element={<FindId />} />
            <Route path="/restore-password" element={<RestorePassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </BlankLayout>
      )}
    </Fragment>
  );
};

const QueryClientElement = () => {
  const { add } = useSnackbar();
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (e) => {
        console.log(e);
        const error = e as unknown as ApiError;
        const message = error?.errors?.length
          ? error?.errors?.at(0)?.message
          : error?.message;
        add({
          message: message ?? ERROR_MESSAGE,
          variant: 'error',
        });
      },
    }),
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: 0,
        onError: (e) => {
          const error = e as unknown as ApiError;
          const message = error?.errors?.length
            ? error?.errors?.at(0)?.message
            : error?.message;
          add({
            message: message ?? ERROR_MESSAGE,
            variant: 'error',
          });
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AppChildren />
    </QueryClientProvider>
  );
};
function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={null}>
        <SnackbarProvider
          maxSnack={10}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}
        >
          <QueryClientElement />
        </SnackbarProvider>
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
