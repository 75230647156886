import { Stack } from '@mui/material';

export interface ITimer {
  count?: number;
}
export const Timer = ({ count = 0 }: ITimer) => {
  const hour = parseInt((count / (100 * 60) / 60).toString());
  const minutes = parseInt(((count / (100 * 60)) % 60).toString());
  const seconds = parseInt(((count / 100) % 60).toString());
  const milliseconds = count % 100;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        fontWeight: 400,
        fontSize: '1rem',
        color: theme.color.grey[900],
      })}
    >
      {hour.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}:{milliseconds.toString().padStart(2, '0')}
    </Stack>
  );
};
