import { styled } from '@mui/material';
import { TestCategories } from '@modules/meta';
import CustomTheme from '@src/core/types/theme';

const Ul = styled('ul')`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.color.grey[50]};
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
  display: flex;
  margin: 0;
  flex-direction: column;
  list-style: none;
  height: fit-content;
`;

const Li = styled('li', { shouldForwardProp: (props) => props !== 'active' })`
  width: 136px;
  height: 40px;
  color: ${({ theme, active }: { active: boolean; theme?: CustomTheme }) =>
    active ? theme?.color?.indigo[700] : theme?.color?.grey[700]};
  background-color: ${({ theme, active }: { active: boolean; theme?: CustomTheme }) =>
    active ? theme?.color?.indigo[50] : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme?.color?.grey[100]};
  }
  font-weight: 700;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
`;
export interface SnbProps {
  menu?: TestCategories;
  onChange: (code: string) => void;
  value: string;
}
export const Snb = ({ value, onChange, menu = [] }: SnbProps) => {
  return (
    <Ul>
      {menu?.map(({ categoryCode, categoryName }) => (
        <Li
          key={categoryCode}
          active={categoryCode === value}
          onClick={() => onChange(categoryCode)}
        >
          {categoryName}
        </Li>
      ))}
    </Ul>
  );
};
