import { styled } from '@mui/material';
import CustomTheme from '@src/core/types/theme';
import { css } from '@emotion/react';
import { ScoreUnits, ScoreUnitsProps } from '../ScoreUnits';
import { Button, IconButton } from '@mui/material';
import { IconIpad } from '@src/core/icons';
import { ReactNode, useCallback, useState } from 'react';
import { CommentPopup } from '@src/components/Test/CommentPopup';
import { Measurements } from '@src/modules/meta';
import { ScoreUnitTypeCode } from '@src/core/constants/enum';

export const ScoreInfoCol = styled('div')`
  height: 40px;
  display: table-cell;
  vertical-align: top;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
  :not(:last-of-type) {
    border-right: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
  }
`;

export const ColWrap = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Col = styled('div')`
  padding: 12px 16px;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
  :not(:last-of-type) {
    border-right: ${({ theme }) => `1px solid ${theme.color.grey[200]}`};
  }
`;

export const RowContainer = styled('div')`
  width: 100%;
  display: table-row;
  font-size: 16px;
  font-weight: ${({ type }: { theme?: CustomTheme; type: 'td' | 'th' }) =>
    type === 'th' ? 700 : 400};
  color: ${({ theme, type }: { theme?: CustomTheme; type: 'td' | 'th' }) =>
    type === 'th' ? theme?.color?.grey[600] : theme?.color?.grey[900]};
`;

interface RowProps
  extends Measurements,
    Pick<ScoreUnitsProps, 'isDetail' | 'categoryCode' | 'pointLength'> {
  no: number;
  header: string[];
  name?: string;
  questionId?: number;
}
const Row = ({
  displayName,
  observation,
  scoreInfo,
  measurementMappingId,
  no,
  header,
  name,
  isDetail,
  categoryCode,
  questionId = 0,
  pointLength,
}: RowProps) => {
  const [commentOpen, setCommentOpen] = useState(false);

  const handleOpenCommentPopup = useCallback(() => {
    setCommentOpen(true);
  }, []);
  const rowNum = `${no + 1}`.padStart(2, '0');
  const data: { [key: string]: ReactNode } = {
    rowNum,
    label: name ?? displayName,
    screenShare: (
      <IconButton>
        <IconIpad />
      </IconButton>
    ),
    observation,
    comment: (
      <Button
        variant="outlined"
        color="inherit"
        onClick={handleOpenCommentPopup}
        sx={{ minWidth: 'max-content' }}
      >
        특이사항
      </Button>
    ),
  };

  const handleCancelCommentPopup = useCallback(() => {
    setCommentOpen(false);
  }, []);

  return (
    <RowContainer key={rowNum} type="td">
      {header?.map((id) => {
        const type = scoreInfo?.unitType?.code;
        return id === 'scoreInfo' ? (
          <ScoreInfoCol key={id}>
            <ColWrap
              css={css`
                padding: ${
                  type === ScoreUnitTypeCode.POINT ||
                  type === ScoreUnitTypeCode.CHOICE ||
                  type === ScoreUnitTypeCode.CHOICE2
                    ? 0
                    : type === ScoreUnitTypeCode.PERCENT
                    ? '15px 22px 0'
                    : '12px'
                };
                height: '100%'};
                justify-content: ${
                  type === ScoreUnitTypeCode.POINT ? 'normal' : 'center'
                };
              `}
            >
              <ScoreUnits
                isDetail={isDetail}
                categoryCode={categoryCode}
                disabled={
                  categoryCode?.startsWith('001:001:004') &&
                  scoreInfo?.unitType?.code === ScoreUnitTypeCode.SPEED
                }
                questionId={questionId}
                scoreInfo={scoreInfo}
                measurementMappingId={measurementMappingId}
                pointLength={pointLength}
              />
            </ColWrap>
          </ScoreInfoCol>
        ) : (
          <Col key={id}>
            <ColWrap>{data[id]}</ColWrap>
          </Col>
        );
      })}
      {commentOpen ? (
        <CommentPopup
          isDetail={isDetail}
          onCancel={handleCancelCommentPopup}
          measurementMappingId={measurementMappingId}
          categoryCode={categoryCode}
          questionId={questionId}
        />
      ) : null}
    </RowContainer>
  );
};

export interface MeasurementRowsProps
  extends Pick<RowProps, 'header' | 'name' | 'isDetail' | 'categoryCode' | 'questionId'> {
  measurements?: Measurements[];
  no?: number;
}
export const MeasurementRows = ({
  measurements,
  no,
  name,
  header,
  questionId,
  categoryCode,
  isDetail,
}: MeasurementRowsProps) => {
  let firstScorePointLength = 0;
  return (
    <>
      {measurements?.map((measurements, index) => {
        if (
          !firstScorePointLength &&
          measurements?.scoreInfo?.unitType?.code === ScoreUnitTypeCode.POINT &&
          measurements?.scoreInfo?.scoreDescription?.scores?.length
        ) {
          firstScorePointLength =
            measurements?.scoreInfo?.scoreDescription?.scores?.length;
        }
        return (
          <Row
            {...measurements}
            no={no || index}
            key={index}
            header={header}
            name={name}
            pointLength={firstScorePointLength}
            isDetail={isDetail}
            categoryCode={categoryCode}
            questionId={questionId}
          />
        );
      })}
    </>
  );
};
