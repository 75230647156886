import { PropsWithChildren, ReactNode } from 'react';
import { styled } from '@mui/material';
import { IconBack } from '@core/icons';
import { IconButton } from '@mui/material';
const Wrap = styled('div')`
  background-color: ${({ theme }) => theme?.color?.white};
  border-radius: 8px;
  padding: 24px 40px 40px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;
const Title = styled('div')`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.25px;
  color: ${({ theme }) => theme?.color?.grey['800']};
`;

const ArrowWrap = styled('div')`
  height: 32px;
  min-height: 32px;
  margin-bottom: 24px;
`;

const BackButton = styled(IconButton)`
  padding: 0;
`;
export interface PageLayoutProps extends PropsWithChildren {
  title?: string;
  onBack?: () => void;
  headerButtons?: ReactNode;
}

export const Header = ({
  headerButtons,
  title,
  onBack,
}: Omit<PageLayoutProps, 'children'>) => (
  <>
    <ArrowWrap>
      {onBack ? (
        <BackButton onClick={onBack}>
          <IconBack />
        </BackButton>
      ) : null}
    </ArrowWrap>
    <HeaderWrap>
      <Title>{title}</Title>
      {headerButtons}
    </HeaderWrap>
  </>
);
export const PageLayout = ({ children, ...headerProps }: PageLayoutProps) => {
  return (
    <Wrap>
      <Header {...headerProps} />
      {children}
    </Wrap>
  );
};
