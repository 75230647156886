import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/common';
import { QueryOptions } from '@core/types/react-query';
import { getPatient } from '../api';
import { PATIENT_KEY } from '../key';
import { Patient } from '../types';

export const usePatient = (
  params: number,
  options?: QueryOptions<Patient, AxiosError<ApiError>>,
) => {
  return useQuery<Patient, AxiosError<ApiError>, Patient>({
    ...options,
    queryKey: [PATIENT_KEY, params],
    queryFn: () => getPatient(params),
  });
};
