import api from '@core/lib/api';
import {
  FindIdParams,
  FindIdResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RestorePasswordRequest,
} from './types';

export const loginUrl = `api/auth/sign-in`;
export async function login(request: LoginRequest): Promise<LoginResponse> {
  const { data } = await api({
    url: loginUrl,
    method: 'post',
    data: request,
  });
  return data;
}
export async function duplicateCheck(loginId: string): Promise<null> {
  const { data } = await api({
    url: `api/auth/check/duplicated-login-id`,
    method: 'get',
    params: { loginId },
  });
  return data;
}

export async function register(request: RegisterRequest): Promise<null> {
  const { data } = await api({
    url: `api/auth/sign-up`,
    method: 'post',
    data: {
      ...request,
      isAdmin: true,
    },
  });
  return data;
}

export async function findId(params: FindIdParams): Promise<FindIdResponse> {
  const { data } = await api({
    url: `api/auth/find-login-id`,
    method: 'get',
    params,
  });
  return data;
}

export async function restorePassword(request: RestorePasswordRequest): Promise<null> {
  const { data } = await api({
    url: `api/auth/restore-password`,
    method: 'patch',
    data: request,
  });
  return data;
}
