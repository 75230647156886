import dayjs from 'dayjs';
import { Gnb, GnbProps } from '@components/Test/Gnb';
import { Box, Stack } from '@mui/material';
import { Header as PageHeader } from '@components/Layout/PageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { usePatient } from '@modules/patient';
import { useTestCategoryList } from '@modules/meta';
import { getFirstCategoryCode } from '@components/Test/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { useSaveTest } from '@core/hooks/useSaveTest';
import { useResetRecoilState } from 'recoil';
import { test } from '@src/recoils/test';
import { getTestType } from '@src/core/lib/utils';

export interface HeaderProps extends Pick<GnbProps, 'isDetail'> {
  thirdCategoryCode?: string;
  onChange: (code: string) => void;
}

const prefix = getTestType();
const PatientInfo = () => {
  const { patientId } = useParams();

  const { data: patient } = usePatient(Number(patientId));

  return (
    <Stack
      direction="row"
      gap={3}
      sx={(theme) => ({
        color: theme.color.grey[900],
        fontSize: '1rem',
        fontWeight: 400,
        mt: '-22px',
        mb: '40px',
      })}
    >
      <div>{`${patient?.name} (${patient?.sex})`}</div>
      <div>{`${(patient?.dateOfBirth ?? '')?.replaceAll('-', '.')} (${
        dayjs().year() - dayjs(patient?.dateOfBirth).year()
      }세)`}</div>
    </Stack>
  );
};
export const Header = ({ isDetail, thirdCategoryCode = '', onChange }: HeaderProps) => {
  const { patientId, firstCategoryCode, sessionId, testRecordId } = useParams();
  const resetTests = useResetRecoilState(test);
  const { check, moveConfirm, save } = useSaveTest(
    Number(patientId),
    Number(sessionId),
    Number(testRecordId),
  );

  const { data = [] } = useTestCategoryList(
    {
      code: firstCategoryCode ?? '',
      depth: 3,
    },
    {
      enabled: !!firstCategoryCode,
    },
  );
  useEffect(() => {
    if (data?.length) {
      onChange(getFirstCategoryCode(data));
    }
  }, [onChange, data]);

  useEffect(() => {
    return () => {
      resetTests();
    };
  }, []);

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (!isDetail) {
      if (check()) {
        moveConfirm.open({
          cancelButtonProps: {
            onClick: () => {
              navigate(`/${prefix}/patient/${patientId}`);
            },
          },
          okButtonProps: {
            onClick: () => {
              save();
              navigate(`/${prefix}/patient/${patientId}`);
              moveConfirm.close();
            },
          },
        });
        return;
      }
    }

    navigate(`/${prefix}/patient/${patientId}`);
  }, [check, isDetail, moveConfirm, save, patientId, navigate]);

  const category = useMemo(() => data?.at(0), [data]);

  const handleChangeActiveCategoryCode = useCallback(
    (code: string) => {
      if (!isDetail) {
        if (check()) {
          moveConfirm.open({
            cancelButtonProps: {
              onClick: () => {
                onChange(code);
              },
            },
            okButtonProps: {
              onClick: async () => {
                try {
                  await save();
                  onChange(code);
                } catch (e) {
                  console.log(e);
                } finally {
                  moveConfirm.close();
                }
              },
            },
          });
          return;
        }
      }

      onChange(code);
    },
    [moveConfirm, isDetail, onChange, save, check],
  );
  return (
    <>
      <Box
        borderRadius={1}
        px={5}
        py={3}
        sx={{
          background: 'white',
        }}
      >
        <PageHeader onBack={handleBack} title={category?.categoryName ?? ''} />
        <PatientInfo />
        <Gnb
          isDetail={isDetail}
          categories={category?.children ?? []}
          onChange={handleChangeActiveCategoryCode}
          value={thirdCategoryCode}
        />
      </Box>
      {moveConfirm.rendered}
    </>
  );
};
