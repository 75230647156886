import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarKey, VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import { useCallback } from 'react';
import { grey } from '../constants/colors';
export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const action = useCallback(
    (key: SnackbarKey) => (
      <Button
        onClick={() => {
          closeSnackbar(key);
        }}
        style={{ border: 'none', padding: 0, minWidth: 0 }}
      >
        <CloseIcon
          sx={{
            color: grey[400],
          }}
        />
      </Button>
    ),
    [closeSnackbar],
  );

  const add = ({
    message,
    variant = 'success',
  }: {
    message: string;
    variant?: VariantType;
  }) => {
    enqueueSnackbar(message, { variant, action });
  };

  return { add };
};
