import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent as MuiDialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps,
  DialogContentProps,
  DialogActionsProps,
} from '@mui/material';
import { IconClose } from '@src/core/icons';
import { styled } from '@mui/material';
import { IconButton, Button, ButtonProps } from '@mui/material';
import { MouseEvent, useCallback } from 'react';

interface ActionButtonProps extends ButtonProps {
  display?: boolean;
}
export interface DialogProps extends Omit<MuiDialogProps, 'onClose'> {
  onClose: () => void;
  titleProps?: DialogTitleProps;
  contentProps?: DialogContentProps;
  dialogActionProps?: DialogActionsProps;
  okButtonProps?: ActionButtonProps;
  cancelButtonProps?: ActionButtonProps;
}

const DialogTitle = styled(MuiDialogTitle)`
  padding-left: 16px;
`;

const DialogContent = styled(MuiDialogContent)`
  overflow-y: hidden;
  padding: 16px 24px;
`;

const DialogHeaderActions = styled('div')`
  display: flex;
  justify-content: end;
`;

const DialogHeader = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0px;
  > svg {
    margin: 4px;
    cursor: pointer;
  }
`;
export const Dialog = ({
  title,
  open,
  children,
  titleProps,
  contentProps,
  cancelButtonProps,
  okButtonProps,
  dialogActionProps,
  onClose,
  ...props
}: DialogProps) => {
  const { display: cancelDisplay, ...cancelButtonOptions } = {
    children: '취소',
    variant: 'outlined' as ButtonProps['variant'],
    color: 'inherit' as ButtonProps['color'],
    display: true,
    ...cancelButtonProps,
    onClick: (e: MouseEvent<HTMLButtonElement>) => {
      if (cancelButtonProps?.onClick) {
        cancelButtonProps.onClick(e);
      }
      handleClose();
    },
  };

  const { display: okDisplay, ...okButtonOptions } = {
    children: '확인',
    variant: 'contained' as ButtonProps['variant'],
    display: true,
    ...okButtonProps,
  };
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  return (
    <MuiDialog open={open} onClose={handleClose} {...props}>
      <DialogHeader>
        <DialogHeaderActions>
          <IconButton onClick={handleClose}>
            <IconClose fontSize="large" width="32" height="32" />
          </IconButton>
        </DialogHeaderActions>
        <DialogTitle {...titleProps}>{title}</DialogTitle>
      </DialogHeader>

      <DialogContent {...contentProps}>{children}</DialogContent>
      {okDisplay || cancelDisplay ? (
        <DialogActions {...dialogActionProps}>
          {cancelDisplay ? <Button {...cancelButtonOptions} /> : null}
          {okDisplay ? <Button {...okButtonOptions} /> : null}
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
};
