import { DefaultValue, selectorFamily } from 'recoil';
import { test } from './atom';
import { cloneDeep } from 'lodash';
import { ScoreRecordInfos } from '@src/modules/patientTest';

export const scoreRecordInfosSelector = selectorFamily({
  key: 'test-score-record-infos-selector',
  get:
    ({
      categoryCode,
      questionId,
      measurementMappingId,
    }: {
      categoryCode: string;
      questionId: number;
      measurementMappingId: number;
    }) =>
    ({ get }) => {
      const result = get(test);
      return result
        ?.find(({ testCategoryCode }) => testCategoryCode === categoryCode)
        ?.questionRecords?.find(({ questionId: id }) => questionId === id)
        ?.scoreRecords?.find(
          ({ measurementMappingId: id }) => measurementMappingId === id,
        );
    },
  set:
    ({
      categoryCode,
      questionId,
      measurementMappingId,
    }: {
      categoryCode: string;
      questionId: number;
      measurementMappingId: number;
    }) =>
    ({ get, set }, value) => {
      const result = cloneDeep(get(test));
      const currentTestIndex = result?.findIndex(
        ({ testCategoryCode }) => testCategoryCode === categoryCode,
      );
      if (value instanceof DefaultValue) {
        return;
      } else {
        if (currentTestIndex !== -1) {
          const currentTest = result[currentTestIndex];
          currentTest.updated = true;
          const questionRecords = currentTest?.questionRecords ?? [];
          const currentQuestionIndex = questionRecords?.findIndex(
            ({ questionId: id }) => questionId === id,
          );
          if (currentQuestionIndex !== -1) {
            const currentQuestion = questionRecords[currentQuestionIndex];
            currentQuestion.questionId = questionId;
            const currentMeasurementIndex = currentQuestion.scoreRecords?.findIndex(
              ({ measurementMappingId: id }) => measurementMappingId === id,
            );
            if (value) {
              if (currentMeasurementIndex !== -1) {
                currentQuestion.scoreRecords[currentMeasurementIndex] = {
                  ...currentQuestion.scoreRecords[currentMeasurementIndex],
                  ...value,
                };
              } else {
                currentQuestion.scoreRecords.push({
                  diagnosticMemo: null,
                  testScoreRecordId: null,
                  ...value,
                  measurementMappingId,
                });
              }
            }
          } else {
            if (value) {
              questionRecords.push({
                questionId,
                testQuestionRecordId: null,
                scoreRecords: [
                  {
                    diagnosticMemo: null,
                    testScoreRecordId: null,
                    ...value,
                    measurementMappingId,
                  },
                ],
              });
            }
          }
        } else {
          if (value) {
            result.push({
              testCategoryRecordId: null,
              updated: true,
              testCategoryCode: categoryCode,
              questionRecords: [
                {
                  questionId,
                  testQuestionRecordId: null,
                  scoreRecords: [
                    {
                      diagnosticMemo: null,
                      testScoreRecordId: null,
                      ...value,
                      measurementMappingId,
                    },
                  ],
                },
              ],
            });
          }
        }
      }

      set(test, result);
    },
});

export const testSelector = selectorFamily({
  key: 'test-selector',
  get:
    (categoryCode: string) =>
    ({ get }) => {
      const result = cloneDeep(get(test));
      const currentTest = result?.find(
        ({ testCategoryCode }) => testCategoryCode === categoryCode,
      );
      return currentTest;
    },
  set:
    (categoryCode) =>
    ({ get, set }, value) => {
      const result = cloneDeep(get(test));
      const currentTestIndex = result?.findIndex(
        ({ testCategoryCode }) => testCategoryCode === categoryCode,
      );
      if (currentTestIndex !== -1) {
        result[currentTestIndex] = {
          ...result[currentTestIndex],
          ...value,
        };
      } else {
        result.push({
          testCategoryRecordId: null,
          testCategoryCode: categoryCode,
          questionRecords: [],
          ...value,
        });
      }

      set(test, result);
    },
});

export const audioSelector = selectorFamily({
  key: 'test-audio-selector',
  get:
    (categoryCode: string) =>
    ({ get }) => {
      const result = cloneDeep(get(test));
      const currentTest = result?.find(
        ({ testCategoryCode }) => testCategoryCode === categoryCode,
      );
      return {
        audio: currentTest?.audio,
        duration: currentTest?.duration,
        regions: currentTest?.regions,
      };
    },
});

export const scoreRecordsSelector = selectorFamily({
  key: 'test-score-record-selector',
  get:
    ({ categoryCode, questionId }: { categoryCode: string; questionId: number }) =>
    ({ get }) => {
      const result = get(test);
      return result
        ?.find(({ testCategoryCode }) => testCategoryCode === categoryCode)
        ?.questionRecords?.find(({ questionId: id }) => questionId === id)?.scoreRecords;
    },
  set:
    ({ categoryCode, questionId }: { categoryCode: string; questionId: number }) =>
    ({ get, set }, value) => {
      const result = cloneDeep(get(test));
      const currentTestIndex = result?.findIndex(
        ({ testCategoryCode }) => testCategoryCode === categoryCode,
      );
      if (value instanceof DefaultValue) {
        return;
      } else {
        const scoreRecords =
          value?.map((v) => {
            return {
              diagnosticMemo: null,
              testScoreRecordId: null,
              ...v,
            };
          }) ?? [];
        if (currentTestIndex !== -1) {
          const currentTest = result[currentTestIndex];
          currentTest.updated = true;
          const questionRecords = currentTest?.questionRecords ?? [];
          const currentQuestionIndex = questionRecords?.findIndex(
            ({ questionId: id }) => questionId === id,
          );
          if (currentQuestionIndex !== -1) {
            const currentQuestion = questionRecords[currentQuestionIndex];
            currentQuestion.questionId = questionId;
            if (value) {
              const valueMap = new Map(value.map((v) => [v.measurementMappingId, v]));
              const scoreRecords = (currentQuestion?.scoreRecords || []).reduce(
                (acc: ScoreRecordInfos[], record) => {
                  if (valueMap.has(record.measurementMappingId)) {
                    const v = valueMap.get(record.measurementMappingId);
                    acc.push({
                      ...record,
                      ...v,
                    });
                    valueMap.delete(record.measurementMappingId);
                  } else {
                    acc.push(record);
                  }
                  return acc;
                },
                [],
              );

              valueMap.forEach((v) => {
                scoreRecords.push({
                  diagnosticMemo: null,
                  testScoreRecordId: null,
                  ...v,
                });
              });

              currentQuestion.scoreRecords = scoreRecords;
            }
          } else {
            if (value) {
              questionRecords.push({
                questionId,
                testQuestionRecordId: null,
                scoreRecords,
              });
            }
          }
        } else {
          if (value) {
            result.push({
              testCategoryRecordId: null,
              updated: true,
              testCategoryCode: categoryCode,
              questionRecords: [
                {
                  questionId,
                  testQuestionRecordId: null,
                  scoreRecords,
                },
              ],
            });
          }
        }
      }

      set(test, result);
    },
});
