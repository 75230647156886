import { selector } from 'recoil';
import { auth } from './atom';

export const userSelector = selector({
  key: 'auth-user-selector',
  get: ({ get }) => {
    const value = get(auth);
    return value?.user;
  },
  set: ({ set, get }, value) => {
    const newValue = { ...get(auth), user: value };
    set(auth, newValue);
  },
});
