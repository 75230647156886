import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/common';
import { QueryOptions } from '@core/types/react-query';
import { getTestCategories } from '../api';
import { META_KEY, CATEGORY_KEY } from '../key';
import { TestCategories, TestCategoriesRequest } from '../types';

export const useTestCategoryList = (
  params: TestCategoriesRequest,
  options?: QueryOptions<TestCategories, AxiosError<ApiError>>,
) => {
  return useQuery<TestCategories, AxiosError<ApiError>, TestCategories>({
    ...options,
    queryKey: [META_KEY, CATEGORY_KEY, params],
    queryFn: () => getTestCategories(params),
  });
};
