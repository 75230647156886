import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError } from '@core/types/common';
import { QueryOptions } from '@core/types/react-query';
import { getTestDetail } from '../api';
import { TEST_KEY } from '../key';
import { TestDetailParams, TestDetailResponse } from '../types';

export const useTestDetail = (
  params: TestDetailParams,
  options?: QueryOptions<TestDetailResponse, AxiosError<ApiError>>,
) => {
  return useQuery<TestDetailResponse, AxiosError<ApiError>, TestDetailResponse>({
    ...options,
    queryKey: [TEST_KEY, 'detail', params],
    queryFn: () => getTestDetail(params),
  });
};
